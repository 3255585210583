import { type AssetUsageType } from "@prisma/client";
import { assetUsageTypeOptions } from "../../common/static/standard/formSelectOption";
import { type QuestionOption } from "../interfaces";

export function getAssetUsageTypeOption(
  assetUsageType: AssetUsageType,
): QuestionOption {
  const usageType = assetUsageTypeOptions.find(
    (option) => option.value === assetUsageType,
  );

  if (!usageType) {
    throw new Error(`${assetUsageType} should be present`);
  }

  return usageType;
}
