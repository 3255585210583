import { type Category } from "../../mappings";
import { hasCoCustomer } from "../../utils/answersConditionsManager";

const getIterations: Category["iterations"] = (answers) => {
  const coCustomersCount = hasCoCustomer(answers) ? 2 : 1;
  return coCustomersCount;
};

export const categoriesPatrimonial: Category[] = [
  {
    slug: "case",
    name: "Projet",
  },
  {
    slug: "company-info",
    name: "Société",
  },
  {
    slug: "real-estate-patrimony",
    name: "Patrimoine immobilier",
    shouldDisplayIterationInfo: "asset-count",
  },
  {
    slug: "company-info-extra",
    name: "Informations financières",
  },
  {
    slug: "situation",
    name: "Situation",
    shouldDisplayIterationInfo: "co-customer",
    iterations: (answers) => getIterations(answers),
  },
  {
    slug: "eligibility",
    name: "Eligibilité",
  },
];
