export const COUNTRIES_FR_AND_DOMTOM = [
  "FR",
  "GP",
  "BL",
  "MQ",
  "GF",
  "RE",
  "YT",
  "PF",
  "WF",
  "NC",
  "PM",
];
