import { type Category } from "../../mappings";

export const categoriesObo: Category[] = [
  {
    slug: "real-estate-patrimony",
    name: "Patrimoine immobilier",
    shouldDisplayIterationInfo: "asset-count",
  },
  {
    slug: "situation",
    name: "Situation",
    shouldDisplayIterationInfo: "co-customer",
  },
  {
    slug: "revenues",
    name: "Revenus",
    shouldDisplayIterationInfo: "co-customer",
  },
  {
    slug: "charges",
    name: "Charges",
    shouldDisplayIterationInfo: "co-customer",
  },
  {
    slug: "patrimony",
    name: "Patrimoine financier",
    shouldDisplayIterationInfo: "co-customer",
  },

  {
    slug: "eligibility",
    name: "Eligibilité",
  },
];
