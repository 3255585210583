import { AssetOwnershipType } from "@prisma/client";
import { booleanOptions } from "~/modules/common/static/common/formSelectOption";
import { QuestionSimpleSlug } from "../../enums";
import { type Branch } from "../../interfaces";
import {
  hasCoCustomer,
  hasMainResidenceInAssets,
} from "../../utils/answersConditionsManager";
import { questionsSenior } from "./questions";

export const branchesSenior: Branch[] = [
  {
    slug: "case-type",
    questions: [
      questionsSenior.find(
        (q) => q.slug === QuestionSimpleSlug.seniorLoansAccept,
      )!,
    ],
    handleNext: (answers, navigation) => {
      const answerFirstQuestion = answers.find(
        (a) => a.questionSlug === QuestionSimpleSlug.seniorLoansAccept,
      )?.value;

      if (answerFirstQuestion === booleanOptions[0]?.value) {
        return {
          ...navigation,
          redirect: "/onboarding/standard",
        };
      }

      return {
        ...navigation,
        branchSlug: "case-info",
      };
    },
    // Cannot go back, this is the first branch
    handleBack: (answers, navigation) => navigation,
  },
  {
    slug: "case-info",
    questions: [
      // questionsSenior.find((q) => q.slug === QuestionSimpleSlug.CasePurpose)!,
      questionsSenior.find(
        (q) => q.slug === QuestionSimpleSlug.FinancingRequestedAmount,
      )!,
      questionsSenior.find(
        (q) => q.slug === QuestionSimpleSlug.FinancingUsageDescription,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-start",
        categoryIteration: 1,
        categorySlug: "real-estate-patrimony",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "case-type",
      };
    },
  },
  {
    slug: "asset-creation-start",
    questions: [
      questionsSenior.find((q) => q.slug === QuestionSimpleSlug.AssetAddress)!,
      questionsSenior.find((q) => q.slug === QuestionSimpleSlug.AssetValue)!,
      questionsSenior.find((q) => q.slug === QuestionSimpleSlug.AssetSurface)!,
    ],
    handleNext: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-loans",
    }),
    handleBack: (answers, navigation) => {
      if (navigation.categoryIteration > 1) {
        return {
          ...navigation,
          branchSlug: "asset-creation-add-new",
          categoryIteration: navigation.categoryIteration - 1,
        };
      } else {
        return {
          ...navigation,
          branchSlug: "case-info",
          categorySlug: "case",
        };
      }
    },
  },
  {
    slug: "asset-creation-loans",
    questions: [
      questionsSenior.find((q) => q.slug === QuestionSimpleSlug.AssetLoans)!,
    ],
    handleNext: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-asset-type",
    }),
    handleBack: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-start",
    }),
  },
  {
    slug: "asset-creation-asset-type",
    questions: [
      questionsSenior.find((q) => q.slug === QuestionSimpleSlug.AssetType)!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-usage-type",
      };
    },
    handleBack: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-loans",
    }),
  },
  {
    slug: "asset-creation-usage-type",
    questions: [
      questionsSenior.find(
        (q) => q.slug === QuestionSimpleSlug.AssetUsageType,
      )!,
    ],
    handleNext: (answers, navigation) => {
      const assetUsageType = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetUsageType &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      return {
        ...navigation,
        branchSlug:
          assetUsageType === "rental"
            ? "asset-creation-rent"
            : "asset-creation-owner",
      };
    },
    handleBack: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-asset-type",
    }),
  },
  {
    slug: "asset-creation-rent",
    questions: [
      questionsSenior.find(
        (q) => q.slug === QuestionSimpleSlug.AssetRentAnnualAmount,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-owner",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-usage-type",
      };
    },
  },
  {
    slug: "asset-creation-owner",
    questions: [
      questionsSenior.find(
        (q) => q.slug === QuestionSimpleSlug.AssetOwnershipType,
      )!,
    ],
    handleNext: (answers, navigation) => {
      const assetOwnerShipType = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetOwnershipType &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;
      if (assetOwnerShipType === AssetOwnershipType.company) {
        return {
          ...navigation,
          branchSlug: "asset-creation-owner-company-type",
        };
      }
      if (assetOwnerShipType === AssetOwnershipType.indivision) {
        return {
          ...navigation,
          branchSlug: "asset-creation-owner-undivided",
        };
      }
      if (assetOwnerShipType === AssetOwnershipType.propre) {
        return {
          ...navigation,
          branchSlug: "asset-creation-add-new",
        };
      }
      throw new Error("Unknown ownership type");
    },
    handleBack: (answers, navigation) => {
      const assetUsageType = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetUsageType &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;
      return {
        ...navigation,
        branchSlug:
          assetUsageType === "rental"
            ? "asset-creation-rent"
            : "asset-creation-usage-type",
      };
    },
  },
  {
    slug: "asset-creation-owner-undivided",
    questions: [
      questionsSenior.find(
        (q) => q.slug === QuestionSimpleSlug.AssetJointOwnerInfo,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-add-new",
        branchIteration: 1,
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-owner",
      };
    },
  },
  {
    slug: "asset-creation-owner-company-type",
    questions: [
      questionsSenior.find(
        (q) => q.slug === QuestionSimpleSlug.AssetCompanyType,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-owner-company-shareholders",
        branchIteration: 1,
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-owner",
      };
    },
  },
  {
    slug: "asset-creation-owner-company-shareholders",
    questions: [
      questionsSenior.find(
        (q) => q.slug === QuestionSimpleSlug.AssetCompanyShareholderInfo,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-add-new",
        branchIteration: 1,
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-owner-company-type",
      };
    },
  },
  {
    slug: "asset-creation-add-new",
    questions: [
      questionsSenior.find((q) => q.slug === QuestionSimpleSlug.AssetAddNew)!,
    ],
    handleNext: (answers, navigation) => {
      const shouldAddNewAsset = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetAddNew &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      if (shouldAddNewAsset === "true") {
        return {
          ...navigation,
          branchSlug: "asset-creation-start",
          categoryIteration: navigation.categoryIteration + 1,
        };
      } else {
        return {
          ...navigation,
          branchSlug: "customer-info-general",
          categorySlug: "situation",
        };
      }
    },
    handleBack: (answers, navigation) => {
      const ownerShipType = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetOwnershipType &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      if (ownerShipType === AssetOwnershipType.propre) {
        return {
          ...navigation,
          branchSlug: "asset-creation-owner",
        };
      }
      if (ownerShipType === AssetOwnershipType.indivision) {
        return {
          ...navigation,
          branchSlug: "asset-creation-owner-undivided",
        };
      }
      if (ownerShipType === AssetOwnershipType.company) {
        return {
          ...navigation,
          branchSlug: "asset-creation-owner-company-shareholders",
        };
      }

      throw new Error("Unknown ownership type");
    },
  },
  {
    slug: "customer-info-general",
    questions: [
      questionsSenior.find((q) => q.slug === QuestionSimpleSlug.CaseType)!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "customer-info-common",
        categorySlug: "situation",
        shouldDisplayIterationInfo: "co-customer",
        categoryIteration: 1,
      };
    },
    handleBack: (answers, navigation) => {
      const previousCategoryIterationsCount = answers.filter(
        (a) => a.questionSlug === QuestionSimpleSlug.AssetAddNew,
      ).length;

      return {
        ...navigation,
        branchSlug: "asset-creation-add-new",
        categoryIteration: previousCategoryIterationsCount,
        categorySlug: "real-estate-patrimony",
      };
    },
  },
  {
    slug: "customer-info-common",
    questions: [
      questionsSenior.find((q) => q.slug === QuestionSimpleSlug.CustomerAge)!,
    ],
    handleNext: (answers, navigation) => {
      if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
        return {
          ...navigation,
          branchSlug: "customer-info-common",
          categoryIteration: 2,
        };
      } else {
        if (hasMainResidenceInAssets(answers)) {
          return {
            ...navigation,
            categorySlug: "eligibility",
            exitPage: "personal",
          };
        } else {
          return {
            ...navigation,
            branchSlug: "customer-info-address",
            categorySlug: "situation",
            categoryIteration: 1,
          };
        }
      }
    },
    handleBack: (answers, navigation) => {
      if (hasCoCustomer(answers) && navigation.categoryIteration === 2) {
        return {
          ...navigation,
          branchSlug: "customer-info-common",
          categoryIteration: 1,
        };
      } else {
        return {
          ...navigation,
          branchSlug: "customer-info-general",
          categorySlug: "situation",
        };
      }
    },
  },
  {
    slug: "customer-info-address",
    questions: [
      questionsSenior.find(
        (q) => q.slug === QuestionSimpleSlug.CustomerAddress,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        categorySlug: "eligibility",
        exitPage: "personal",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "customer-info-common",
        categorySlug: "situation",
        categoryIteration: hasCoCustomer(answers) ? 2 : 1,
      };
    },
  },
  {
    slug: "charges-housing-rent-info",
    questions: [
      questionsSenior.find(
        (q) => q.slug === QuestionSimpleSlug.HousingRentMonthlyAmount,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "charges-additional-loans-info",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "charges-housing-status",
      };
    },
  },
  {
    slug: "charges-housing-loan-info",
    questions: [
      questionsSenior.find((q) => q.slug === QuestionSimpleSlug.HousingLoans)!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "charges-additional-loans-info",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "charges-housing-status",
      };
    },
  },
];
