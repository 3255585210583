import { type QuestionSimpleSlug } from "src/modules/onboarding/enums";
import {
  type Answer,
  type FlatAnswerNested,
  type SimpleAnswerValue,
} from "src/modules/onboarding/interfaces";
import {
  schemaAddress,
  schemaAnswerNested,
  schemaSimpleAnswerValue,
} from "src/modules/onboarding/schemas";
import { type EnumLike, z } from "zod";

interface Params {
  slug: QuestionSimpleSlug;
  categoryIndex: number;
}

export function getValueFromSimpleAnswer(
  answers: Answer[],
  params: Params,
): SimpleAnswerValue | undefined {
  const { slug, categoryIndex } = params;
  const answer = answers.find(
    (question) =>
      question.questionSlug === slug &&
      question.categoryIteration === categoryIndex,
  );

  if (answer) {
    const valitatedAnswerValue = schemaSimpleAnswerValue.parse(answer.value);

    return valitatedAnswerValue;
  } else return undefined;
}

export function getStringValueFromSimpleAnswer(
  answers: Answer[],
  params: Params,
): string {
  return z.string().parse(getValueFromSimpleAnswer(answers, params));
}
export function getOptionalStringValueFromSimpleAnswer(
  answers: Answer[],
  params: Params,
): string | undefined {
  return z.string().optional().parse(getValueFromSimpleAnswer(answers, params));
}

export function getNumberValueFromSimpleAnswer(
  answers: Answer[],
  params: Params,
): number {
  return z.coerce.number().parse(getValueFromSimpleAnswer(answers, params));
}
export function getOptionalNumberValueFromSimpleAnswer(
  answers: Answer[],
  params: Params,
): number | undefined {
  return z.number().optional().parse(getValueFromSimpleAnswer(answers, params));
}

export function getEnumValueFromSimpleAnswer<T extends EnumLike>(
  answers: Answer[],
  params: Params,
  enumLike: T,
): T[keyof T] {
  const value = z
    .nativeEnum<T>(enumLike)
    .parse(getValueFromSimpleAnswer(answers, params));

  return value;
}

export function getOptionalEnumValueFromSimpleAnswer<T extends EnumLike>(
  answers: Answer[],
  params: Params,
  enumLike: T,
): T[keyof T] | undefined {
  const value = z
    .nativeEnum<T>(enumLike)
    .optional()
    .parse(getValueFromSimpleAnswer(answers, params));

  return value;
}

export function getAddressValueFromSimpleAnswer(
  answers: Answer[],
  params: Params,
) {
  return schemaAddress.parse(getValueFromSimpleAnswer(answers, params));
}

export function getOptionalAddressValueFromSimpleAnswer(
  answers: Answer[],
  params: Params,
) {
  return schemaAddress
    .optional()
    .parse(getValueFromSimpleAnswer(answers, params));
}

export function getValuesFromRepeatableAnswer(
  answers: Answer[],
  params: Params,
): FlatAnswerNested[] | undefined {
  const { slug, categoryIndex } = params;
  const filteredAnswers = answers
    .filter(
      (question) =>
        question.questionSlug === slug &&
        question.categoryIteration === categoryIndex,
    )
    .map((answer) => {
      return {
        ...answer,
        value: z.array(schemaAnswerNested).parse(answer.value),
      };
    })
    .filter((answer) => answer.value.length > 0);

  if (!filteredAnswers || filteredAnswers.length === 0) return undefined;

  const flattenedNestedValues = filteredAnswers.map((answer) => {
    const simplifiedNestedAnswer = answer.value?.reduce<FlatAnswerNested>(
      (acc, nestedAnswer) => ({
        ...acc,
        [nestedAnswer.questionSlug]: nestedAnswer.value,
      }),
      {},
    );

    return simplifiedNestedAnswer;
  });

  return flattenedNestedValues;
}
