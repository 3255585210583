import { type Category } from "../../mappings";
import { hasCoCustomer } from "../../utils/answersConditionsManager";

const getIterations: Category["iterations"] = (answers) => {
  const coCustomersCount = hasCoCustomer(answers) ? 2 : 1;
  return coCustomersCount;
};

export const categories: Category[] = [
  {
    slug: "case",
    name: "Projet",
  },
  {
    slug: "real-estate-patrimony",
    name: "Patrimoine immobilier",
    shouldDisplayIterationInfo: "asset-count",
  },
  {
    slug: "situation",
    name: "Situation",
    shouldDisplayIterationInfo: "co-customer",

    iterations: (answers) => getIterations(answers),
  },
  {
    slug: "revenues",
    name: "Revenus",
    shouldDisplayIterationInfo: "co-customer",
    iterations: (answers) => getIterations(answers),
  },
  {
    slug: "charges",
    name: "Charges",
    shouldDisplayIterationInfo: "co-customer",
    iterations: (answers) => getIterations(answers),
  },
  {
    slug: "patrimony",
    name: "Patrimoine financier",
    shouldDisplayIterationInfo: "co-customer",
    iterations: (answers) => getIterations(answers),
  },

  {
    slug: "eligibility",
    name: "Eligibilité",
  },
];
