import { CustomerCaseType, QuestionSimpleSlug } from "../../enums";
import { type Category } from "../../mappings";

const getIterations: Category["iterations"] = (answers) => {
  const coCustomerAnswer =
    answers.find((a) => a.questionSlug === QuestionSimpleSlug.CasePurpose)
      ?.value === CustomerCaseType.Duo;
  const coCustomersCount = coCustomerAnswer ? 2 : 1;
  return coCustomersCount;
};

export const categoriesSenior: Category[] = [
  {
    slug: "case",
    name: "Projet",
  },
  {
    slug: "real-estate-patrimony",
    name: "Patrimoine immobilier",
    shouldDisplayIterationInfo: "asset-count",
  },
  {
    slug: "situation",
    name: "Situation",
    shouldDisplayIterationInfo: "co-customer",

    iterations: (answers) => getIterations(answers),
  },

  {
    slug: "eligibility",
    name: "Eligibilité",
  },
];
