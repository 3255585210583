import { TaxType } from "@prisma/client";
import { QuestionSimpleSlug } from "../../enums";
import { type Branch } from "../../interfaces";
import { taxTypeSchema } from "../../schemas";
import { isRentalAsset } from "../../utils/answersConditionsManager";
import { questionsPatrimonial } from "./questions";

export const branchesPatrimonial: Branch[] = [
  {
    slug: "case-type",
    questions: [
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.CompanyType,
      )!,
    ],
    handleNext: (answers, navigation) => {
      if (
        answers.find((a) => a.questionSlug === QuestionSimpleSlug.CompanyType)
          ?.value === "solo"
      ) {
        return {
          ...navigation,
          redirect: "/onboarding/standard",
        };
      }

      return {
        ...navigation,
        branchSlug: "case-info",
      };
    },
    // Cannot go back, this is the first branch
    handleBack: (answers, navigation) => {
      return navigation;
    },
  },
  {
    slug: "case-info",
    questions: [
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.CasePurpose,
      )!,
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.FinancingRequestedAmount,
      )!,
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.FinancingUsageDescription,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "company-info",
        categorySlug: "company-info",
      };
    },
    handleBack: (answers, navigation) => ({
      ...navigation,
      branchSlug: "case-type",
    }),
  },
  {
    slug: "company-info",
    questions: [
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.CompanySiret,
      )!,
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.CompanyAccountValue,
      )!,
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.CompanyTaxType,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-start",
        categoryIteration: 1,
        categorySlug: "real-estate-patrimony",
      };
    },
    handleBack: (answers, navigation) => ({
      ...navigation,
      branchSlug: "case-type",
      categorySlug: "case",
    }),
  },
  {
    slug: "asset-creation-start",
    questions: [
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.AssetAddress,
      )!,
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.AssetValue,
      )!,
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.AssetSurface,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-loans",
      };
    },
    handleBack: (answers, navigation) => {
      const hasAddedNewAsset = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetAddNew &&
          a.categoryIteration < navigation.categoryIteration,
      )?.value;

      if (hasAddedNewAsset === "true") {
        return {
          ...navigation,
          branchSlug: "asset-creation-add-new",
          categoryIteration: navigation.categoryIteration - 1,
        };
      }
      return {
        ...navigation,
        branchSlug: "company-info",
        categorySlug: "company-info",
      };
    },
  },
  {
    slug: "asset-creation-loans",
    questions: [
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.AssetLoans,
      )!,
    ],
    handleNext: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-asset-type",
    }),
    handleBack: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-start",
    }),
  },
  {
    slug: "asset-creation-asset-type",
    questions: [
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.AssetType,
      )!,
    ],
    handleNext: (answers, navigation) => {
      const isRental = isRentalAsset(answers, navigation.categoryIteration);

      return {
        ...navigation,
        branchSlug: isRental ? "asset-creation-rent" : "asset-creation-add-new",
      };
    },
    handleBack: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-loans",
    }),
  },
  {
    slug: "asset-creation-rent",
    questions: [
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.AssetRentAnnualAmount,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-add-new",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-asset-type",
      };
    },
  },
  {
    slug: "asset-creation-add-new",
    questions: [
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.AssetAddNew,
      )!,
    ],
    handleNext: (answers, navigation) => {
      const shouldAddNewAsset = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetAddNew &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      if (shouldAddNewAsset === "true") {
        return {
          ...navigation,
          branchSlug: "asset-creation-start",
          categoryIteration: navigation.categoryIteration + 1,
          categorySlug: "real-estate-patrimony",
        };
      } else {
        const taxType = answers.find(
          (a) => a.questionSlug === QuestionSimpleSlug.CompanyTaxType,
        )?.value;

        const parsedTaxType = taxTypeSchema.parse(taxType);

        if (parsedTaxType === TaxType.is) {
          return {
            ...navigation,
            branchSlug: "companyIS-info-common",
            categorySlug: "company-info-extra",
            categoryIteration: 1,
          };
        } else if (parsedTaxType === TaxType.ir) {
          return {
            ...navigation,
            branchSlug: "companyIR-info-common",
            categorySlug: "company-info-extra",
            categoryIteration: 1,
          };
        }

        throw new Error("Invalid tax type");
      }
    },
    handleBack: (answers, navigation) => {
      const isRental = isRentalAsset(answers, navigation.categoryIteration);

      return {
        ...navigation,
        categorySlug: "real-estate-patrimony",
        branchSlug: isRental
          ? "asset-creation-rent"
          : "asset-creation-usage-type",
      };
    },
  },
  {
    slug: "companyIS-info-common",
    questions: [
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.CompanyResult,
      )!,
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.CompanyDepreciationCharge,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        exitPage: "personal",
        categorySlug: "eligibility",
      };
    },
    handleBack: (answers, navigation) => {
      const assetAnswers = answers.filter(
        (a) => a.questionSlug === QuestionSimpleSlug.AssetUsageType,
      );

      const lastAsset = assetAnswers.reduce((prev, current) =>
        prev.categoryIteration > current.categoryIteration ? prev : current,
      );
      return {
        ...navigation,
        branchSlug: "asset-creation-add-new",
        categorySlug: "real-estate-patrimony",
        categoryIteration: lastAsset.categoryIteration,
      };
    },
  },
  {
    slug: "companyIR-info-common",
    questions: [
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.CompanyNetResult,
      )!,
      questionsPatrimonial.find(
        (q) => q.slug === QuestionSimpleSlug.CompanyLoanInterest,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        exitPage: "personal",
        categorySlug: "eligibility",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-add-new",
        categorySlug: "real-estate-patrimony",
      };
    },
  },
];
