import { AssetType, AssetUsageType } from "@prisma/client";
import { CustomerCaseType, QuestionSimpleSlug } from "../enums";
import { type Answer } from "../interfaces";
import { assetTypeSchema } from "../schemas";

export const isMainHomeInfoFilled = (answers: Answer[]) => {
  const mainHomeAnswer = answers.find(
    (a) =>
      a.questionSlug === QuestionSimpleSlug.AssetUsageType &&
      a.value === AssetUsageType.main_residence,
  );
  return !!mainHomeAnswer;
};

export const hasCoCustomer = (answers: Answer[]) => {
  const coCustomerAnswer =
    answers.find((a) => a.questionSlug === QuestionSimpleSlug.CaseType)
      ?.value === CustomerCaseType.Duo;
  return !!coCustomerAnswer;
};

export const getCustomerJobType = (iteration: number, answers: Answer[]) => {
  const customerJobTypeAnswer = answers.find(
    (a) =>
      a.questionSlug === QuestionSimpleSlug.CustomerJobType &&
      a.categoryIteration === iteration,
  );
  return customerJobTypeAnswer?.value;
};

export const getCustomerJobEmployeeType = (
  iteration: number,
  answers: Answer[],
) => {
  const customerJobEmployeeTypeAnswer = answers.find(
    (a) =>
      a.questionSlug === QuestionSimpleSlug.CustomerJobEmployeeType &&
      a.categoryIteration === iteration,
  );
  return customerJobEmployeeTypeAnswer?.value;
};

export const isCompany = (answers: Answer[]): boolean => {
  return (
    answers.find((a) => a.questionSlug === QuestionSimpleSlug.CaseType)
      ?.value === CustomerCaseType.Company
  );
};

export const hasMainResidenceInAssets = (answers: Answer[]): boolean => {
  const mainResidence = answers.find(
    (a) =>
      a.questionSlug === QuestionSimpleSlug.AssetUsageType &&
      a.value === AssetUsageType.main_residence,
  );
  return !!mainResidence;
};

export const isRentalAsset = (
  answers: Answer[],
  categoryIteration: number,
): boolean => {
  const assetTypeValue = answers.find(
    (a) =>
      a.questionSlug === QuestionSimpleSlug.AssetType &&
      a.categoryIteration === categoryIteration,
  )?.value;

  const assetType = assetTypeSchema.parse(assetTypeValue);

  return (
    assetType === AssetType.commercial_property ||
    assetType === AssetType.residential_property
  );
};
