import {
  type AssetOwnershipType,
  type AssetType,
  type AssetUsageType,
} from "@prisma/client";

export const AssetOwnershipDisplay: Record<AssetOwnershipType, string | null> =
  {
    propre: "propre",
    indivision: "indivision",
    company: "société",
    other: null,
  };

export const AssetTypeDisplay: Record<AssetType, string> = {
  residential_property: "Bien résidentiel",
  commercial_property: "Bien commercial",
  industrial_property: "Bien industriel",
};

export const AssetUsageTypeDisplay: Record<AssetUsageType, string> = {
  main_residence: "Résidence Principale",
  secondary_residence: "Résidence Secondaire",
  rental: "Bien locatif",
};
